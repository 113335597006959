<template>
  <div class="d-flex justify-content-center">
    <div class="ScaleRates d-flex align-items-center">
      <div class="card border-light shadow p-3 mb-5 bg-white card-quote">
        <div id="formScaleRates">
          <h1 style="color: #263238"><b>Scale rates</b></h1>
          <hr />
          <div class="row mt-3">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <button class="btn btn-link btn-file" @click="uploadExcel">
                <i class="fa fa-file-upload"></i> Upload Excel File<input
                  @input="setExcelFile"
                  type="file"
                  accept=".xlsx, .xls, .csv"
                />
              </button>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <button class="btn btn-link" @click="downloadFormat">
                <i class="fa fa-file-download"></i> Download Format
              </button>
            </div>
          </div>
          <div>
            <template v-if="excelExists">
              <span>{{ message }}</span>
            </template>
          </div>
          <div id="formQuote">
            <FormQuote :excelExists="excelExists" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormQuote from "../components/FormQuote.vue";
import swal from "sweetalert";

export default {
  name: "ScaleRates",
  components: {
    FormQuote
  },
  data() {
    return {
      excelExists: false,
      excelName: null,
      message: null,
      file: ""
    };
  },
  methods: {
    uploadExcel() {
      this.excelExists = true;
      document.getElementById("formQuote").style.pointerEvents = "none";
      document.getElementById("formQuote").style.opacity = "0.5";
    },
    async setExcelFile(e) {
      this.message = e.target.files[0].name;
      this.file = e.target.files[0];
      let formData = new FormData();
      formData.append("file", this.file);
      this.response = await this.$http
        .post(
          process.env.VUE_APP_BACK_ROUTE + `/api/v1/quotes/file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        )
        .then(function() {
          swal({
            title: "Great!",
            text: "The quote has been sent!",
            icon: "success"
          });
        })
        .catch(function(err) {
          swal({
            title: "There is something wrong",
            text: err.response.data,
            icon: "warning",
            dangerMode: true
          });
        });
    },
    downloadFormat() {
      const link = document.createElement("a");
      link.href = "/Scale-Format.xls";
      link.setAttribute("download", "Scale-Format.xls");
      link.click();
    }
  }
};
</script>

<style scoped>
#formScaleRates {
  margin-top: 40px;
}

.ScaleRates {
  max-width: 1600px;
  min-height: 84.1vh;
}

.btn-link {
  color: #17a2b8 !important;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.card-quote {
  border-radius: 20px;
}
</style>
