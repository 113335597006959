import Vue from "vue";
import Router from "vue-router";
import ScaleRates from "../views/ScaleRates.vue";
import Tables from "../views/Tables.vue";
import NotFound from "../views/NotFound.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "ScaleRates",
      component: ScaleRates
    },
    {
      path: "/quote/:id",
      name: "Tables",
      component: Tables,
      props: true
    },
    {
      path: "/404",
      name: "notFound",
      component: NotFound
    },
    {
      path: "*" /** El * Siempre al final o regex*/,
      redirect: "/404"
    }
  ]
});

export default router;
