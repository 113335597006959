<template>
  <div class="tables">
    <div id="quoteInfo">
      <QuoteInfo />
    </div>

    <hr />

    <template v-if="thereIsInfo">
      <div
        v-if="response.data.state == 2"
        id="alert"
        class="d-flex justify-content-end"
      >
        <div class="alert alert-info alert-dismissible fade show" role="alert">
          <i class="fa fa-spinner"></i> Quote in process...
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div
        v-if="response.data.state == 3"
        id="alert"
        class="d-flex justify-content-end"
      >
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <i class="fa fa-exclamation"></i> Quote failed
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>

      <div
        v-if="response.data.state == 4"
        id="alert"
        class="d-flex justify-content-end"
      >
        <div
          class="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          <i class="fa fa-check"></i> Quote ready
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </template>

    <div class="form-inline my-2 my-lg-0">
      <input
        v-model="number_of_carriers"
        class="form-control mr-sm-2 box-shadow"
        type="number"
        placeholder="Number of carriers"
      />
      <button
        class="btn btn-outline-info my-2 my-sm-0 box-shadow"
        @click="getCarriers"
      >
        <i class="fa fa-share"></i>
      </button>
    </div>

    <div v-if="thereIsInfo" id="trips">
      <template v-for="trip in number_of_trips">
        <div
          v-bind:key="trip"
          class="card border-light shadow p-3 mb-5 bg-white rounded"
          style="margin-bottom: 100px"
        >
          <div class="d-flex justify-content-start">
            <h2 style="color: #263238">
              <b
                >Trip {{ trip }}: {{ response.data.trips[trip - 1].zip_from }} -
                {{ response.data.trips[trip - 1].zip_to }}</b
              >
            </h2>
          </div>

          <template v-for="(weightBrake, index) in response.data.weight_brakes">
            <div v-bind:key="weightBrake">
              <TheTable
                v-model="data_table"
                :trip="trip - 1"
                :weightBrakeName="weightBrake"
                :data="response"
                :tableName="tableNames[trip - 1][index]"
                :ratesInfo="response.data.rates"
                :id="index"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
    <ButtonGenerateExcel name="Generate excel" :data="excel_file" />
  </div>
</template>

<script>
import { calculatePricePerLib, calculateRates } from "../common/common";
import TheTable from "../components/TheTable.vue";
import ButtonGenerateExcel from "../components/buttons/generateExcel.vue";
import QuoteInfo from "../components/QuoteInfo.vue";

export default {
  name: "Tables",
  components: {
    TheTable,
    ButtonGenerateExcel,
    QuoteInfo
  },
  data() {
    return {
      response: null,
      number_of_trips: 0,
      tableNames: [],
      thereIsInfo: false,
      data_table: null,
      data_tables: [],
      selects_values_array: [],
      selects_values_array_aux: [],
      excel_file: {},
      number_of_carriers: null
    };
  },
  mounted() {
    this.create();
  },
  beforeUpdate() {
    if (this.data_table != null) {
      let trip = this.data_table.trip;
      this.excel_file.tables[trip].push(this.data_table.info);
      this.data_table = null;
    }
  },
  methods: {
    async create() {
      this.response = await this.$http
        .get(
          process.env.VUE_APP_BACK_ROUTE +
            `/api/v1/quotes/${this.$route.params.id}`
        )
        .catch(err => {
          if (err.response.status == 400) {
            this.$router.push({ name: "notFound" });
          }
          return err;
        });
      this.number_of_trips = this.response.data.trips.length;
      this.getTableName();
      for (let trip in this.response.data.trips) {
        calculatePricePerLib(this.response, trip);
        calculateRates(this.response, trip);
      }
      this.excel_file.name = this.$route.params.id;
      this.excel_file.tables = new Array(this.response.data.trips.length);
      for (let table in this.response.data.trips) {
        this.excel_file.tables[table] = [];
      }
      this.changeNamecarriers();
      // console.info("From mounted",this.response)
      this.thereIsInfo = true;
    },
    getTableName() {
      for (let trip in this.response.data.trips) {
        let namesAux = [];
        for (let weight_brake in this.response.data.trips[trip].weight_brakes) {
          namesAux.push(
            this.response.data.trips[trip].weight_brakes[weight_brake].shp_id
          );
        }
        this.tableNames.push(namesAux);
      }
    },
    changeNamecarriers() {
      for (let trip in this.response.data.trips) {
        for (let weight_brake in this.response.data.trips[trip].weight_brakes) {
          let counter_carriers = 1;
          for (let carrier in this.response.data.trips[trip].weight_brakes[
            weight_brake
          ].carriers) {
            this.response.data.trips[trip].weight_brakes[weight_brake].carriers[
              carrier
            ].name =
              counter_carriers +
              "-" +
              this.response.data.trips[trip].weight_brakes[weight_brake]
                .carriers[carrier].name;
            counter_carriers = counter_carriers + 1;
          }
          for (let average in this.response.data.trips[trip].weight_brakes[
            weight_brake
          ].averages) {
            this.response.data.trips[trip].weight_brakes[weight_brake].averages[
              average
            ].name =
              counter_carriers +
              "-" +
              this.response.data.trips[trip].weight_brakes[weight_brake]
                .averages[average].name;
            counter_carriers = counter_carriers + 1;
          }
        }
      }
    },
    async getCarriers() {
      this.thereIsInfo = false;
      console.info(this.number_of_carriers);
      this.response = await this.$http
        .get(
          process.env.VUE_APP_BACK_ROUTE +
            `/api/v1/quotes/${this.$route.params.id}?carrier=${this.number_of_carriers}`
        )
        .catch(err => {
          if (err.response.status == 400) {
            this.$router.push({ name: "notFound" });
          }
          return err;
        });
      console.info(this.response.data);
      this.number_of_trips = this.response.data.trips.length;
      this.getTableName();
      for (let trip in this.response.data.trips) {
        calculatePricePerLib(this.response, trip);
        calculateRates(this.response, trip);
      }
      this.excel_file.name = this.$route.params.id;
      this.excel_file.tables = new Array(this.response.data.trips.length);
      for (let table in this.response.data.trips) {
        this.excel_file.tables[table] = [];
      }
      this.changeNamecarriers();
      this.thereIsInfo = true;
    }
  }
};
</script>

<style scoped>
.tables {
  min-height: 83.1vh;
}

#trips {
  margin-top: 20px;
  margin-bottom: 80px;
}

.box-shadow {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
