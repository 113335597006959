<template>
  <div class="selectNmfc">
    <div class="input-group mb-3">
      <select
        class="custom-select box-shadow"
        v-model="selectedClass"
        @change="handleInput"
      >
        <option value="-1" disabled>NMFC class</option>
        <option v-for="nmfcOption in nmfcOptions" v-bind:key="nmfcOption">
          {{ nmfcOption }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectNmfc",
  data() {
    return {
      nmfcOptions: [
        "50",
        "55",
        "60",
        "65",
        "70",
        "77.5",
        "85",
        "92.5",
        "100",
        "110",
        "125",
        "150",
        "175",
        "200",
        "250",
        "300",
        "400",
        "500"
      ],
      selectedClass: "-1"
    };
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
