<template>
  <div class="TheInput">
    <template v-if="name == 'notify to:'">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text box-shadow">{{ name }}</span>
        </div>
        <input
          :placeholder="[[placeholder]]"
          required
          type="email"
          v-model="valueInput"
          @input="handleInput"
          class="form-control box-shadow"
          aria-describedby="basic-addon3"
        />
      </div>
    </template>
    <template v-if="name != 'notify to:'">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text box-shadow">{{ name }}</span>
        </div>
        <input
          :placeholder="[[placeholder]]"
          required
          type="text"
          v-model="valueInput"
          @input="handleInput"
          class="form-control box-shadow"
          aria-describedby="basic-addon3"
        />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TheInput",
  props: {
    name: String,
    placeholder: String
  },
  data() {
    return {
      valueInput: this.value
    };
  },
  methods: {
    handleInput() {
      this.$emit("input", this.valueInput);
    }
  }
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
