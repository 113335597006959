<template>
  <div class="buttonQuote">
    <div class="text-right" style="margin-top: 10px;">
      <button type="button" class="btn btn-info box-shadow" @click="sendQuote">
        <i class="fa fa-paper-plane"></i>
        {{ name }}
      </button>
    </div>
    <template v-if="!dataIsFine">
      <div id="alert" class="d-flex justify-content-end">
        <div
          class="alert alert-warning alert-dismissible fade show"
          role="alert"
        >
          <i class="fa fa-exclamation"></i> All fields are obligatory
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
            @click="closeAlert"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "buttonQuote",
  props: {
    name: String,
    data: Object
  },
  data() {
    return {
      dataIsFine: true,
      createTable: false
    };
  },
  methods: {
    sendQuote() {
      if (
        this.data.zip_from == null ||
        this.data.zip_to == null ||
        this.data.nmfc == null ||
        this.data.density == null ||
        this.data.weight_brakes == null ||
        this.data.units_weight == null ||
        this.data.rates == null ||
        this.data.email == null
      ) {
        this.dataIsFine = false;
      } else {
        this.dataIsFine = true;
        this.createTable = true;
        this.$emit("input", this.createTable);
      }
    },
    closeAlert() {
      this.dataIsFine = true;
    }
  }
};
</script>

<style scoped>
#alert {
  margin-top: 20px;
}

.box-shadow {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
