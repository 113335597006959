<template>
  <div class="buttonGenerateExcel" @click="generateExcelFile">
    <div class="text-right">
      <a title="Generate Excel" class="float">
        <i class="fa fa-file-excel my-float"></i>
      </a>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";

export default {
  name: "buttonGenerateExcel",
  props: {
    name: String,
    data: Object
  },
  data() {
    return {
      response: null
    };
  },
  methods: {
    async generateExcelFile() {
      this.response = await this.$http.post(
        process.env.VUE_APP_BACK_ROUTE + `/api/v1/quotes/generate_excel`,
        this.data
      );
      swal({
        title: "Great!",
        text: "The email has been sent!",
        icon: "success"
      });
    }
  }
};
</script>

<style scoped>
.float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #28a745;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}

.my-float {
  margin-top: 22px;
}
</style>
