<template>
  <div v-if="there_is_data" class="TheTable">
    <table
      class="table table-bordered table-hover table-sm box-shadow"
      responsive
      style="margin-top: 30px;"
    >
      <thead class="thead-light table-sm">
        <tr>
          <th
            :colspan="numberOfColumns"
            style="background-color: #17202a; color:white; font-size: 20px;"
          >
            {{ tableName }}
          </th>
        </tr>
        <tr>
          <th width="8%">WEIGHT</th>
          <th colspan="3">CARRIERS</th>
          <th :colspan="numberOfColumns - 7">MARK UP</th>
        </tr>
      </thead>
      <tbody>
        <tr style="font-weight: bold;">
          <th rowspan="100">{{ weightBrakeName }}</th>
          <td colspan="3"></td>
          <td v-for="rate in ratesInfo" v-bind:key="rate" colspan="2">
            + {{ rate }}%
          </td>
        </tr>
        <tr style="font-weight: bold;">
          <td width="16%"></td>
          <td :width="76 / (numberOfColumns - 6) + '%'">$</td>
          <td :width="76 / numberOfColumns + '%'">$/lb</td>
          <template v-for="index in numberColumnsForRate">
            <template v-if="index % 2 != 0">
              <td v-bind:key="index" :width="76 / (numberOfColumns - 6) + '%'">
                $
              </td>
            </template>
            <template v-if="index % 2 == 0">
              <td
                v-bind:key="ratesInfo[index]"
                :width="76 / numberOfColumns + '%'"
              >
                $/lb
              </td>
            </template>
          </template>
        </tr>
        <template v-for="wb in data_.trips[trip].weight_brakes">
          <template>
            <template v-for="(carrier, index) in wb.carriers">
              <template v-if="wb.weight_brake == weightBrakeName">
                <tr v-bind:key="index + carrier.name">
                  <td style="text-align: left;">{{ carrier.name }}</td>
                  <td
                    class="pt-3-half"
                    contenteditable="true"
                    style="font-weight: bold;"
                    :id="`${carrier.name}` + `${id}`"
                  >
                    {{ carrier.price }}
                  </td>
                  <td class="pt-3-half">{{ carrier.price_per_lb }}</td>
                  <template v-for="(rate, index) in carrier.rates">
                    <td
                      v-bind:key="index + rate.price"
                      class="pt-3-half"
                      style="font-weight: bold;"
                    >
                      {{ carrier.rates[index].price }}
                    </td>
                    <td
                      v-bind:key="rate.price_per_lb + index"
                      class="pt-3-half"
                    >
                      {{ carrier.rates[index].price_per_lb }}
                    </td>
                  </template>
                </tr>
              </template>
            </template>
          </template>
        </template>
        <template v-for="wb in data_.trips[trip].weight_brakes">
          <template>
            <template v-for="(average, index) in wb.averages">
              <template v-if="wb.weight_brake == weightBrakeName">
                <tr v-bind:key="average.name + index">
                  <td style="text-align: left;">{{ average.name }}</td>
                  <td class="pt-3-half" style="font-weight: bold;">
                    {{ average.price }}
                  </td>
                  <td class="pt-3-half">{{ average.price_per_lb }}</td>
                  <template v-for="(rate, index) in average.rates">
                    <td
                      v-bind:key="index + rate.price"
                      class="pt-3-half"
                      style="font-weight: bold;"
                    >
                      {{ average.rates[index].price }}
                    </td>
                    <td
                      v-bind:key="rate.price_per_lb + index"
                      class="pt-3-half"
                    >
                      {{ average.rates[index].price_per_lb }}
                    </td>
                  </template>
                </tr>
              </template>
            </template>
          </template>
        </template>
      </tbody>
    </table>

    <div class="row" style="margin-top: 30px;">
      <div class="col">
        <select
          class="custom-select box-shadow"
          v-model="selectedCarrier"
          @change="handleSelects"
        >
          <option value="-1" disabled>Select a carrier</option>
          <option
            v-for="(carrier_option, index) in carriers_names"
            v-bind:key="index + carrier_option"
          >
            {{ carrier_option }}
          </option>
        </select>
      </div>
      <div class="col-2">
        <select
          class="custom-select box-shadow"
          v-model="selectedRate"
          @change="handleSelects"
        >
          <option value="-1" disabled>Select a mark up</option>
          <option v-for="rate_option in data_.rates" v-bind:key="rate_option">
            {{ rate_option }}%
          </option>
        </select>
      </div>
      <div class="col"></div>
      <div class="col">
        <div class="d-flex justify-content-end" id="buttonRecalc">
          <button
            type="button"
            class="btn btn-info box-shadow"
            @click="getCarriersValues"
          >
            <i class="fa fa-calculator"></i> Recalculate table
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { calculatePricePerLib, calculateRates } from "../common/common";

export default {
  name: "TheTable",
  props: {
    trip: Number,
    weightBrakeName: String,
    tableName: String,
    data: Object,
    ratesInfo: Array,
    id: Number
  },
  data() {
    return {
      numberOfColumns: 7,
      numberColumnsForRate: 0,
      zip_from: null,
      zip_to: null,
      carriersValues: [],
      optionsSelected: {
        carrier: null,
        combination: null,
        rate: null
      },
      there_is_data: false,
      carriers_names: [],
      selectedCarrier: "-1",
      selectedRate: "-1",
      selects_values: null,
      data_: this.data.data,
      response: null
    };
  },
  mounted() {
    this.create();
  },
  methods: {
    create() {
      this.numberOfColumns = this.numberOfColumns + this.ratesInfo.length * 2;
      this.numberColumnsForRate = this.ratesInfo.length * 2;
      this.getNamesForSelects(this.data_);
      this.there_is_data = true;
    },
    async getCarriersValues() {
      for (let wb in this.data_.trips[this.trip].weight_brakes) {
        if (
          this.data_.trips[this.trip].weight_brakes[wb].weight_brake ==
          this.weightBrakeName
        ) {
          for (let carrier in this.data_.trips[this.trip].weight_brakes[wb]
            .carriers) {
            this.carriersValues.push({
              name: this.data_.trips[this.trip].weight_brakes[wb].carriers[
                carrier
              ].name,
              price: document.getElementById(
                this.data_.trips[this.trip].weight_brakes[wb].carriers[carrier]
                  .name + this.id
              ).innerHTML
            });
          }
        }
      }
      let shp_id = null;
      for (let wb in this.data_.trips[this.trip].weight_brakes) {
        if (
          this.data_.trips[this.trip].weight_brakes[wb].weight_brake ==
          this.weightBrakeName
        ) {
          shp_id = this.data_.trips[this.trip].weight_brakes[wb].shp_id;
        }
      }
      this.response = await this.$http.post(
        process.env.VUE_APP_BACK_ROUTE +
          `/api/v1/response/${this.$route.params.id}/shp/${shp_id}`,
        this.carriersValues
      );
      this.carriersValues = [];
      calculatePricePerLib(this.response, this.trip);
      calculateRates(this.response, this.trip);
      this.changeNamecarriers();
      this.data_ = this.response.data;
    },
    getNamesForSelects(data) {
      for (let wb in data.trips[this.trip].weight_brakes) {
        if (
          data.trips[this.trip].weight_brakes[wb].weight_brake ==
          this.weightBrakeName
        ) {
          for (let carrier in data.trips[this.trip].weight_brakes[wb]
            .carriers) {
            this.carriers_names.push(
              data.trips[this.trip].weight_brakes[wb].carriers[carrier].name
            );
          }
          for (let average in data.trips[this.trip].weight_brakes[wb]
            .averages) {
            this.carriers_names.push(
              data.trips[this.trip].weight_brakes[wb].averages[average].name
            );
          }
        }
      }
    },
    handleSelects() {
      if (this.selectedCarrier != "-1" && this.selectedRate != "-1") {
        for (let wb in this.data_.trips[this.trip].weight_brakes) {
          if (
            this.data_.trips[this.trip].weight_brakes[wb].weight_brake ==
            this.weightBrakeName
          ) {
            for (let carrier in this.data_.trips[this.trip].weight_brakes[wb]
              .carriers) {
              if (
                this.data_.trips[this.trip].weight_brakes[wb].carriers[carrier]
                  .name == this.selectedCarrier
              ) {
                let rate_aux = this.selectedRate.split("%");
                let rate = Number(rate_aux[0]) / 100;
                let pricePlusRate = (
                  Number(
                    this.data_.trips[this.trip].weight_brakes[wb].carriers[
                      carrier
                    ].price
                  ) *
                    rate +
                  Number(
                    this.data_.trips[this.trip].weight_brakes[wb].carriers[
                      carrier
                    ].price
                  )
                ).toFixed(2);
                let pricePlusRatePerLb = (
                  pricePlusRate /
                  Number(
                    this.data_.trips[this.trip].weight_brakes[wb].weight_brake
                  )
                ).toFixed(2);
                this.selects_values = {
                  trip: this.trip,
                  info: {
                    name:
                      this.data_.trips[this.trip].zip_from +
                      "_" +
                      this.data_.trips[this.trip].zip_to,
                    shp_id: this.data_.trips[this.trip].weight_brakes[wb]
                      .shp_id,
                    carrier_name: this.selectedCarrier,
                    weight_brake: this.data_.trips[this.trip].weight_brakes[wb]
                      .weight_brake,
                    price: pricePlusRate,
                    price_per_lb: pricePlusRatePerLb
                  }
                };
              }
            }
            for (let average in this.data_.trips[this.trip].weight_brakes[wb]
              .averages) {
              if (
                this.data_.trips[this.trip].weight_brakes[wb].averages[average]
                  .name == this.selectedCarrier
              ) {
                let rate_aux = this.selectedRate.split("%");
                let rate = Number(rate_aux[0]) / 100;
                let pricePlusRate = (
                  Number(
                    this.data_.trips[this.trip].weight_brakes[wb].averages[
                      average
                    ].price
                  ) *
                    rate +
                  Number(
                    this.data_.trips[this.trip].weight_brakes[wb].averages[
                      average
                    ].price
                  )
                ).toFixed(2);
                let pricePlusRatePerLb = (
                  pricePlusRate /
                  Number(
                    this.data_.trips[this.trip].weight_brakes[wb].weight_brake
                  )
                ).toFixed(2);
                this.selects_values = {
                  trip: this.trip,
                  info: {
                    name:
                      this.data_.trips[this.trip].zip_from +
                      "_" +
                      this.data_.trips[this.trip].zip_to,
                    shp_id: this.data_.trips[this.trip].weight_brakes[wb]
                      .shp_id,
                    carrier_name: this.selectedCarrier,
                    weight_brake: this.data_.trips[this.trip].weight_brakes[wb]
                      .weight_brake,
                    price: pricePlusRate,
                    price_per_lb: pricePlusRatePerLb
                  }
                };
              }
            }
            this.$emit("input", this.selects_values);
          }
        }
      }
    },
    changeNamecarriers() {
      for (let wb in this.response.data.trips[this.trip].weight_brakes) {
        if (
          this.response.data.trips[this.trip].weight_brakes[wb].weight_brake ==
          this.weightBrakeName
        ) {
          let counter_carriers = 1;
          for (let carrier in this.response.data.trips[this.trip].weight_brakes[
            wb
          ].carriers) {
            this.response.data.trips[this.trip].weight_brakes[wb].carriers[
              carrier
            ].name =
              counter_carriers +
              "-" +
              this.response.data.trips[this.trip].weight_brakes[wb].carriers[
                carrier
              ].name;
            counter_carriers = counter_carriers + 1;
          }
          for (let average in this.response.data.trips[this.trip].weight_brakes[
            wb
          ].averages) {
            this.response.data.trips[this.trip].weight_brakes[wb].averages[
              average
            ].name =
              counter_carriers +
              "-" +
              this.response.data.trips[this.trip].weight_brakes[wb].averages[
                average
              ].name;
            counter_carriers = counter_carriers + 1;
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
