<template>
  <div class="selectUnitsWeight">
    <div class="input-group mb-3">
      <select
        class="custom-select box-shadow"
        v-model="selectedUnits"
        @change="handleInput"
      >
        <option value="-1" disabled>Weight Units</option>
        <option v-for="unitsOption in unitsOptions" v-bind:key="unitsOption">
          {{ unitsOption }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "selectUnitsWeight",
  data() {
    return {
      unitsOptions: ["lbs", "kg", "ton"],
      selectedUnits: "-1"
    };
  },
  methods: {
    handleInput(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>

<style scoped>
.box-shadow {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
