<template>
  <div class="selectAccessorials">
    <div class="input-group mb-3" @change="handleAccessorials">
      <div class="input-group-prepend" id="Dropdown">
        <button
          class="btn btn-info dropdown-toggle box-shadow"
          type="button"
          data-toggle="dropdown"
        >
          Accessorials
        </button>
        <b-dropdown-form class="dropdown-menu" @click="btnToggle">
          <b-form-checkbox-group
            v-model="selectedAccessorials"
            :options="accessorialsOptions"
            stacked
          >
          </b-form-checkbox-group>
        </b-dropdown-form>
      </div>
      <input
        readonly
        type="text"
        v-model="selectedAccessorials"
        class="form-control box-shadow"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "selectAccessorials",
  data() {
    return {
      accessorialsOptions: [
        "Hazmat",
        "Non-stackable",
        "Airport",
        "Bonded",
        "CFS",
        "Alcohol",
        "Limited access",
        "Port",
        "Liftgate delivery",
        "Residential delivery",
        "Limited access delivery",
        "Liftgate pickup",
        "Residential pickup",
        "Limited access pickup",
        "Delivery appt",
        "Residential",
        "TSA"
      ],
      selectedAccessorials: [],
      accessorialsString: null
    };
  },
  methods: {
    handleAccessorials() {
      this.accessorialsString = this.selectedAccessorials.join(",");
      this.$emit("input", this.accessorialsString);
    },
    btnToggle() {
      document
        .getElementById("Dropdown")
        .addEventListener("click", function(event) {
          event.stopPropagation();
        });
    }
  }
};
</script>

<style scoped>
.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
}

.box-shadow {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
