export function calculatePricePerLib(response, trip) {
  for (let weight_brake in response.data.trips[trip].weight_brakes) {
    for (let carrier in response.data.trips[trip].weight_brakes[weight_brake]
      .carriers) {
      let price =
        response.data.trips[trip].weight_brakes[weight_brake].carriers[carrier]
          .price;
      let wb =
        response.data.trips[trip].weight_brakes[weight_brake].weight_brake;
      response.data.trips[trip].weight_brakes[weight_brake].carriers[
        carrier
      ].price_per_lb = (Number(price) / Number(wb)).toFixed(2).toString();
    }
    for (let average in response.data.trips[trip].weight_brakes[weight_brake]
      .averages) {
      let price = Number(
        response.data.trips[trip].weight_brakes[weight_brake].averages[average]
          .price
      );
      let wb = Number(
        response.data.trips[trip].weight_brakes[weight_brake].weight_brake
      );
      response.data.trips[trip].weight_brakes[weight_brake].averages[
        average
      ].price_per_lb = (price / wb).toFixed(2).toString();
    }
  }
}

export function calculateRates(response, trip) {
  for (let weight_brake in response.data.trips[trip].weight_brakes) {
    for (let carrier in response.data.trips[trip].weight_brakes[weight_brake]
      .carriers) {
      let rates = [];
      for (let rate in response.data.rates) {
        let rateAux = Number(response.data.rates[rate]) / 100;
        let price = Number(
          response.data.trips[trip].weight_brakes[weight_brake].carriers[
            carrier
          ].price
        );
        let wb = Number(
          response.data.trips[trip].weight_brakes[weight_brake].weight_brake
        );
        let priceRate = (price * rateAux + price).toFixed(2).toString();
        let priceRatePerLib = (priceRate / wb).toFixed(2).toString();
        rates[rate] = {
          name: response.data.rates[rate],
          price: priceRate,
          price_per_lb: priceRatePerLib
        };
        response.data.trips[trip].weight_brakes[weight_brake].carriers[
          carrier
        ].rates = rates;
      }
    }
    for (let average in response.data.trips[trip].weight_brakes[weight_brake]
      .averages) {
      let rates = [];
      for (let rate in response.data.rates) {
        let rateAux = Number(response.data.rates[rate]) / 100;
        let price = Number(
          response.data.trips[trip].weight_brakes[weight_brake].averages[
            average
          ].price
        );
        let wb = Number(
          response.data.trips[trip].weight_brakes[weight_brake].weight_brake
        );
        let priceRate = (price * rateAux + price).toFixed(2).toString();
        let priceRatePerLib = (priceRate / wb).toFixed(2).toString();
        rates[rate] = {
          name: response.data.rates[rate],
          price: priceRate,
          price_per_lb: priceRatePerLib
        };
        response.data.trips[trip].weight_brakes[weight_brake].averages[
          average
        ].rates = rates;
      }
    }
  }
}
