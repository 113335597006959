<template>
  <div id="TheHeader">
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
      <img
        src="../assets/GLT-LOGISTICS-(TRADEMARK-NEGATIVO).png"
        width="70"
        height="70"
        class="d-inline-block align-top"
        alt=""
      />
      <div>
        <a :href="$router.resolve({ name: 'ScaleRates' }).href">New quote</a>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TheHeader"
};
</script>

<style scoped>
.navbar {
  height: 73px;
  background-color: #17202a !important;
  display: flex;
  justify-content: space-between;
}

a {
  color: #00ff00 !important;
}
</style>
