<template>
  <div id="quoteInfo" class="text-left container">
    <h2><b>Quote information:</b></h2>
    <hr />
    <template v-if="dataExists">
      <b-row>
        <b-col>
          <p><b>Zip from: </b>{{ response.data.zip_from.join(", ") }}</p>
          <p><b>Zip to: </b>{{ response.data.zip_to.join(", ") }}</p>
          <p><b>NMFC class: </b>{{ response.data.nmfc }}</p>
          <p>
            <b>Accessorials: </b>{{ response.data.accessorials.join(", ") }}
          </p>
          <p><b>Density: </b>{{ response.data.density }}</p>
        </b-col>
        <b-col>
          <p><b>Notify to: </b>{{ response.data.email }}</p>
          <p>
            <b>Weight brake: </b>{{ response.data.weight_brakes.join(", ") }}
          </p>
          <p><b>Units weight: </b>{{ response.data.units_weight }}</p>
          <p><b>Mark up : </b>{{ response.data.rates.join(", ") }}</p>
          <p><b>Number of trips : </b>{{ response.data.number_of_trips }}</p>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
export default {
  name: "quoteInfo",
  async mounted() {
    this.response = await this.$http.get(
      process.env.VUE_APP_BACK_ROUTE + `/api/v1/quotes/${this.$route.params.id}`
    );
    this.dataExists = true;
  },
  data() {
    return {
      dataExists: false,
      response: null
    };
  }
};
</script>

<style scoped>
#quoteInfo {
  margin-top: 50px;
  color: #263238;
}
</style>
