<template>
  <div id="formQuote">
    <div class="row mt-3">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <TheInput name="zip_from:" placeholder="" v-model="quote.zip_from" />
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <TheInput name="zip_to:" placeholder="" v-model="quote.zip_to" />
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <SelectNmfc v-model="quote.nmfc" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <TheInput
          name="Mark up:"
          placeholder="15,20,25"
          v-model="quote.rates"
        />
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <TheInput
          name="Weight brake:"
          placeholder="100,700,1000"
          v-model="quote.weight_brakes"
        />
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
        <SelectUnitsWeight v-model="quote.units_weight" />
      </div>
      <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12">
        <TheInput name="Density" placeholder="" v-model="quote.density" />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">
        <SelectAccessorials v-model="quote.accessorials" />
      </div>
      <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
        <TheInput
          name="notify to:"
          placeholder="charlie@guane.com.co"
          v-model="quote.email"
        />
      </div>
    </div>
    <div style="margin-top: 20px;">
      <ButtonQuote
        v-model="responseFormQuote.there_is_table"
        name="Send quote"
        :data="quote"
        v-on:input="readyToQuote"
      />
    </div>
    <template v-if="thereIsAnError">
      <div id="alert" class="d-flex justify-content-end">
        <div
          class="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          <i class="fa fa-exclamation"></i> {{ messageError }}
          <button
            type="button"
            class="close box-shadow"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TheInput from "./TheInput.vue";
import ButtonQuote from "../components/buttons/quote.vue";
import SelectNmfc from "../components/selects/nmfc.vue";
import SelectAccessorials from "../components/selects/accessorials.vue";
import SelectUnitsWeight from "../components/selects/unitsWeight.vue";
import swal from "sweetalert";

export default {
  name: "formQuote",
  props: {
    excelExists: Boolean
  },
  components: {
    TheInput,
    ButtonQuote,
    SelectNmfc,
    SelectAccessorials,
    SelectUnitsWeight
  },
  data() {
    return {
      quote: {
        zip_from: null,
        zip_to: null,
        nmfc: null,
        accessorials: null,
        email: null,
        weight_brakes: null,
        units_weight: null,
        density: null,
        rates: null
      },
      quote_copy: null,
      response: null,
      responseFormQuote: {
        response: null,
        there_is_table: false,
        number_of_trips: null,
        weight_brakes: [],
        table_names: [],
        rates: []
      },
      aux_zip_to: null,
      messageError: null,
      thereIsAnError: false
    };
  },
  methods: {
    async readyToQuote() {
      this.organizeQuoteInArrays();
      // console.info("form quote", this.quote_copy)
      this.response = await this.$http
        .post(
          process.env.VUE_APP_BACK_ROUTE + `/api/v1/quotes`,
          this.quote_copy
        )
        .catch(err => {
          if (err.response.status == 400) {
            this.messageError = err.response.data;
            this.thereIsAnError = true;
          }
          return err;
        });
      this.getNumberOfTrips();
      this.getTableName();
      this.responseFormQuote.rates = this.response.data.rates_trips;
      this.responseFormQuote.response = this.response;
      this.responseFormQuote.weight_brakes = this.response.data.weight_brakes;
      swal({
        title: "Great!",
        text: "Quote in process!",
        icon: "success"
      });
      this.$router.push({
        name: "Tables",
        params: { id: this.response.data.id }
      });
    },
    organizeQuoteInArrays() {
      this.quote_copy = {
        zip_from: null,
        zip_to: null,
        nmfc: null,
        accessorials: null,
        email: null,
        weight_brakes: null,
        units_weight: null,
        density: null,
        rates: null
      };
      this.quote_copy.zip_from = this.quote.zip_from.split(",");
      this.quote_copy.zip_to = this.quote.zip_to.split(",");
      this.quote_copy.nmfc = this.quote.nmfc;
      if (this.quote.accessorials != null) {
        this.quote_copy.accessorials = this.quote.accessorials.split(",");
      } else {
        this.quote_copy.accessorials = [];
      }
      this.quote_copy.email = this.quote.email;
      this.quote_copy.weight_brakes = this.quote.weight_brakes.split(",");
      this.quote_copy.units_weight = this.quote.units_weight;
      this.quote_copy.density = this.quote.density;
      this.quote_copy.rates = this.quote.rates.split(",");
    },
    getNumberOfTrips() {
      for (let zip_from in this.response.data.zip_from) {
        for (let zip_to in this.response.data.zip_from[zip_from]) {
          for (let values_zip_to in this.response.data.zip_from[zip_from][
            zip_to
          ]) {
            this.aux_zip_to = values_zip_to;
            this.responseFormQuote.number_of_trips++;
          }
        }
      }
    },
    getTableName() {
      for (let zip_from in this.response.data.zip_from) {
        for (let zip_to in this.response.data.zip_from[zip_from]) {
          for (let values_zip_to in this.response.data.zip_from[zip_from][
            zip_to
          ]) {
            let table_name = zip_from + " - " + values_zip_to;
            this.responseFormQuote.table_names.push(table_name);
          }
        }
      }
    }
  }
};
</script>

<style scoped>
#formQuote {
  margin-top: 50px;
  margin-bottom: 20px;
}

#alert {
  margin-top: 30px;
}

.box-shadow {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
</style>
