<template>
  <div id="app">
    <TheHeader />
    <div style="background: #00FF00; height: 3px;"></div>
    <div id="ScaleRates">
      <router-view></router-view>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader.vue";
import TheFooter from "./components/TheFooter.vue";

export default {
  name: "App",
  components: {
    TheHeader,
    TheFooter
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#ScaleRates {
  padding-top: 20px;
  padding-right: 25px;
  padding-left: 25px;
}
</style>
