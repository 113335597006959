<template>
  <div id="footer">
    <footer class="page-footer font-small unique-color-dark footer-padding">
      <div class="footer-copyright text-center py-3">
        © {{ new Date().getFullYear() }} <b>developed</b> by
        <a href="http://guane.com.co/" target="_blank"> guane.com.co</a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  }
};
</script>

<style scoped>
.unique-color-dark {
  background-color: #17202a !important;
  color: #fafafa !important;
}

.footer-padding {
  padding-top: 8px;
}

a {
  color: #00ff00 !important;
}
</style>
